import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { manufacturerApi } from "./ManufacturerApi";
import { Manufacturer } from "@/discount/model/manufacturer/Manufacturer";

export function useManufacturerService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getManufacturersByIds = async (ids: string[]): Promise<Manufacturer[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await manufacturerApi.getManufacturersByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getActiveManufacturersByName = async (name: string): Promise<Manufacturer[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await manufacturerApi.getActiveManufacturersByName(authHeaders, name);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getManufacturers = async (): Promise<Manufacturer[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await manufacturerApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getActiveManufacturersByName,
    getManufacturersByIds,
    getManufacturers,
  };
}
