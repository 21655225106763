import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export enum DiscountRoutes {
  Search = "discount-search",
  Add = "discount-add",
  Update = "discount-update",
}

export const discountRoutes: RouteRecordRaw[] = [
  {
    path: "/price/discount/search/:search?",
    name: DiscountRoutes.Search,
    component: (): Component => import("./views/DiscountSearch.vue"),
    meta: {
      authRequired: true,
      title: "discount.search-discount",
    },
  },
  {
    path: "/price/discount/add",
    name: DiscountRoutes.Add,
    component: (): Component => import("./views/DiscountAdd.vue"),
    meta: {
      authRequired: true,
      title: "discount.add-discount",
    },
  },
  {
    path: "/price/discount/edit/:id",
    name: DiscountRoutes.Update,
    component: (): Component => import("./views/DiscountUpdate.vue"),
    meta: {
      authRequired: true,
      title: "discount.edit-discount",
    },
  },
];
