import { DiscountProduct } from "./DiscountProduct";
import { DiscountManufacturerGroupLevel } from "./DiscountManufacturerGroupLevel";
import { DiscountRule } from "./DiscountRule";
import { DiscountType } from "./DiscountType";
import { NIL as emptyUuid } from "uuid";

export class Discount {
  id: string = emptyUuid;
  name = "";
  description = "";
  discountType: DiscountType = DiscountType.Standard;
  discountRule: DiscountRule = DiscountRule.FixedPrice;
  activeFrom = "";
  activeTo = "";
  active = false;
  activeForWebShop = true;
  activeForClient = false;
  clientId: string = emptyUuid;
  products: DiscountProduct[] = [];
  manufacturerGroupLevels: DiscountManufacturerGroupLevel[] = [];
  customerIds: string[] = [];
  customerGroupIds: string[] = [];
  activeForAllProducts = false;
  activeForAllCustomers = false;
}
