import { NIL as emptyUuid } from "uuid";
import { ProductGroupName } from "./ProductGroupName";
import { LanguageIsoType } from "./LanguageIsoType";

export class ProductGroupNameByLanguage {
  id = emptyUuid;
  name = "";

  public static createFromProductGroupName(
    productGroupName: ProductGroupName | null,
    languageIso: LanguageIsoType
  ): ProductGroupNameByLanguage | null {
    if (productGroupName == null) return null;

    const productGroupNameByLanguage = new ProductGroupNameByLanguage();
    productGroupNameByLanguage.id = productGroupName.id;
    productGroupNameByLanguage.name = productGroupName.names.find((x) => x.languageIso === languageIso)?.text ?? "";
    return productGroupNameByLanguage;
  }
}
