import { httpClient, AuthHeaders } from "@cumulus/http";
import { Client } from "@/discount/model/client/Client";

export class ClientApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getClients(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/clients")
      .then((response) => response.data);
  }
}

export const clientApi = new ClientApi();
