import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { Client } from "@/discount/model/client/Client";
import { clientApi } from "./ClientApi";

export function useClientService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllClients = async (): Promise<Client[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.getClients(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllClients };
}
