import type { Ref } from "vue";
import { Discount } from "../model/discount/Discount";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";

export function discountCustomerFunctions(discount: Ref<Discount>, selectedCustomers: Ref<SearchCustomer[]>) {
  const addCustomerToDiscount = (customer: SearchCustomer) => {
    const existingCustomer = discount.value.customerIds.find((x: string) => x === customer.id);

    if (existingCustomer) return;

    selectedCustomers.value.push(customer);
    discount.value.customerIds.push(customer.id);
  };

  const removeCustomerFromDiscount = (customer: SearchCustomer) => {
    discount.value.customerIds = discount.value.customerIds.filter((x: string) => x !== customer.id);
    selectedCustomers.value = selectedCustomers.value.filter((x: SearchCustomer) => x.id !== customer.id);
  };

  return {
    addCustomerToDiscount,
    removeCustomerFromDiscount,
  };
}
