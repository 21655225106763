import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { Product } from "@/discount/model/product/Product";
import { productApi } from "./ProductApi";
import { ProductGroupName } from "@/discount/model/product/ProductGroupName";

export function useProductService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductsByIds = async (ids: string[]): Promise<Product[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProductsByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllProductGroupNames = async (): Promise<ProductGroupName[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProductGroupNames(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getProductsByIds,
    getAllProductGroupNames,
  };
}
