import { DirectiveBinding } from "vue";

const clickOutside = (event: Event, el: HTMLElement, binding: DirectiveBinding) => {
  if (!el.contains(event.target as Node)) {
    binding.value(event);
  }
};

const clickOutsideDirective = {
  beforeMount(el: HTMLElement & { clickOutsideEvent?: EventListener }, binding: DirectiveBinding) {
    el.clickOutsideEvent = (event: Event) => clickOutside(event, el, binding);
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el: HTMLElement & { clickOutsideEvent?: EventListener }) {
    if (el.clickOutsideEvent) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    }
  },
};

export default clickOutsideDirective;
