<template>
  <ManufacturerGroupLevelModal
    v-if="manufacturerGroupLevelModalVisible"
    v-model:visible="manufacturerGroupLevelModalVisible"
    :selectedManufacturerGroupLevelInfo="selectedManufaturerHierarchy"
    :allAvailableManufacturers="allAvailableManufacturers"
    @manufacturerGroupLevelAdded="addManufacturerGroupLevelDiscount"
    @manufacturerGroupLevelUpdated="updateManufacturerGroupLevelDiscount"
    @manufacturerGroupLevelModalClosed="manufacturerGroupLevelModalVisible = false"
  />
  <div class="col-span-12">
    <ManufacturerGroupLevelList
      :manufacturerGroupLevelInfos="manufacturerGroupLevelInfos"
      :loading="loading"
      @editManufacturerGroupLevelClicked="emit('openManufacturerGroupLevelModal', $event)"
      @confirmRemoveManufacturerGroupLevelClicked="removeManufacturerGroupLevelDiscount"
      @discountPercentageUpdated="updateDiscountPercentage"
    />
  </div>
  <div class="col-span-12">
    <div class="flex justify-end mt-6">
      <Button
        class="mr-8 text-sm"
        data-testid="c-add-new-manufacturer-group-level-discount"
        id="c-add-new-manufacturer-group-level-discount"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openManufacturerGroupLevelModal', selectedManufaturerHierarchy)"
      >
        <i class="pi pi-plus c-success-button c-circular-icon text-xs rounded-3xl p-2 text-white"></i>
        <span class="text-sm font-bold">{{
          t("discount.manufacturer-group-level.add-manufacturer-group-level-discount")
        }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import ManufacturerGroupLevelModal from "./ManufacturerGroupLevelModal.vue";
import ManufacturerGroupLevelList from "./ManufacturerGroupLevelList.vue";
import { ref, watch } from "vue";
import { DiscountManufacturerGroupLevel } from "../../model/discount/DiscountManufacturerGroupLevel";
import { ManufacturerGroupLevelInfo } from "../../model/manufacturer/ManufacturerGroupLevelInfo";
import { useDiscountManufacturerGroupLevelFunctions } from "../../utils/discountManufacturerGroupLevelFunctions";
import { useI18n } from "vue-i18n";
import { Manufacturer } from "@/discount/model/manufacturer/Manufacturer";

const manufacturerGroupLevelDiscounts = defineModel<DiscountManufacturerGroupLevel[]>(
  "manufacturerGroupLevelDiscounts",
  {
    required: true,
  }
);
const selectedManufaturerHierarchy = defineModel<ManufacturerGroupLevelInfo | null>("selectedManufaturerHierarchy", {
  default: null,
});

const manufacturerGroupLevelModalVisible = defineModel<boolean>("manufacturerGroupLevelModalVisible");

const manufacturerGroupLevelInfos = ref<ManufacturerGroupLevelInfo[]>([]);
const { t } = useI18n();

const props = defineProps<{
  existingManufacturerGroupLevelInfos?: ManufacturerGroupLevelInfo[];
  loading: boolean;
  allAvailableManufacturers: Manufacturer[];
}>();

const emit = defineEmits<{
  (e: "openManufacturerGroupLevelModal", value: ManufacturerGroupLevelInfo  | null): void;
}>();

const {
  addManufacturerGroupLevelDiscount,
  removeManufacturerGroupLevelDiscount,
  updateDiscountPercentage,
  updateManufacturerGroupLevelDiscount,
} = useDiscountManufacturerGroupLevelFunctions(manufacturerGroupLevelDiscounts, manufacturerGroupLevelInfos);

watch(
  () => props.existingManufacturerGroupLevelInfos,
  (newValue) => {
    manufacturerGroupLevelInfos.value = newValue ?? [];
  }
);

watch(
  () => manufacturerGroupLevelDiscounts.value,
  (newValue) => {
    if (newValue.length === 0) {
      manufacturerGroupLevelInfos.value = [];
    }
  },
  { deep: true }
);
</script>
