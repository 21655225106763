import { Product } from "@/discount/model/product/Product";
import { ProductGroupName } from "@/discount/model/product/ProductGroupName";
import { ProductHierarchy } from "@/discount/model/product/ProductHierarchy";
import { httpClient, AuthHeaders } from "@cumulus/http";

class ProductApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductsByIds(authHeaders: AuthHeaders, ids: string[]): Promise<Product[]> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/by-ids", ids)
      .then((response) => response.data);
  }

  public async getProductGroupNames(authHeaders: AuthHeaders): Promise<ProductGroupName[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/product-group-names")
      .then((response) => response.data);
  }

  public getProductHierarchies(authHeaders: AuthHeaders): Promise<ProductHierarchy[]> {
    return httpClient(this.uri, authHeaders)
      .get("/product-hierarchies")
      .then((response) => response.data);
  }
}

export const productApi = new ProductApi();
