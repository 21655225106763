import { Ref } from "vue";
import { DiscountManufacturerGroupLevel } from "../model/discount/DiscountManufacturerGroupLevel";
import { ManufacturerGroupLevelInfo } from "../model/manufacturer/ManufacturerGroupLevelInfo";

export function useDiscountManufacturerGroupLevelFunctions(
  manufacturerGroupLevelDiscounts: Ref<DiscountManufacturerGroupLevel[]>,
  manufacturerGroupLevelInfos: Ref<ManufacturerGroupLevelInfo[]>
) {
  const addManufacturerGroupLevelDiscount = (mglInfo: ManufacturerGroupLevelInfo) => {
    const mglDiscount = DiscountManufacturerGroupLevel.createNewFromManufacturerGroupLevelInfo(mglInfo);

    manufacturerGroupLevelDiscounts.value.push(mglDiscount);
    manufacturerGroupLevelInfos.value.push(mglInfo);
  };

  const updateManufacturerGroupLevelDiscount = (mgl: ManufacturerGroupLevelInfo) => {
    const mglDiscount = manufacturerGroupLevelDiscounts.value.find(
      (x: DiscountManufacturerGroupLevel) => x.id === mgl.id
    );

    const mglInfo = manufacturerGroupLevelInfos.value.find((x: ManufacturerGroupLevelInfo) => x.id === mgl.id);

    if (!mglDiscount || !mglInfo) return;

    mglInfo.manufacturerId = mgl.manufacturerId;
    mglInfo.manufacturerName = mgl.manufacturerName;
    mglInfo.groupLevel1 = mgl.groupLevel1;
    mglInfo.groupLevel2 = mgl.groupLevel2;
    mglInfo.groupLevel3 = mgl.groupLevel3;
    mglInfo.groupLevel4 = mgl.groupLevel4;
    mglInfo.groupLevel5 = mgl.groupLevel5;
    mglInfo.discountPercentage = mgl.discountPercentage;

    mglDiscount.manufacturerId = mgl.manufacturerId;
    mglDiscount.groupLevel1Id = mgl.groupLevel1.id;
    mglDiscount.groupLevel2Id = mgl.groupLevel2.id;
    mglDiscount.groupLevel3Id = mgl.groupLevel3.id;
    mglDiscount.groupLevel4Id = mgl.groupLevel4.id;
    mglDiscount.groupLevel5Id = mgl.groupLevel5.id;
    mglDiscount.discountPercentage = mgl.discountPercentage;
  };

  const removeManufacturerGroupLevelDiscount = (mglInfo: ManufacturerGroupLevelInfo) => {
    manufacturerGroupLevelDiscounts.value = manufacturerGroupLevelDiscounts.value.filter(
      (x: DiscountManufacturerGroupLevel) => x.id !== mglInfo.id
    );
    manufacturerGroupLevelInfos.value = manufacturerGroupLevelInfos.value.filter(
      (x: ManufacturerGroupLevelInfo) => x.id !== mglInfo.id
    );
  };

  const updateDiscountPercentage = (mglInfo: ManufacturerGroupLevelInfo, discount: number) => {
    const mglDiscount = manufacturerGroupLevelDiscounts.value.find(
      (x: DiscountManufacturerGroupLevel) => x.manufacturerId === mglInfo.manufacturerId
    );

    if (mglDiscount === undefined) return;

    mglInfo.discountPercentage = discount;
    mglDiscount.discountPercentage = discount;
  };

  return {
    addManufacturerGroupLevelDiscount,
    updateManufacturerGroupLevelDiscount,
    removeManufacturerGroupLevelDiscount,
    updateDiscountPercentage,
  };
}
