<template>
  <FloatLabel variant="on">
    <div class="c-datepicker-wrapper">
      <input
        type="datetime-local"
        :data-testid="dataTestId"
        :id="id"
        max="9999-12-31T23:59"
        v-model="activeComputed"
        class="c-date c-calendar-toggler w-full"
        :class="val.dateTime.$error ? 'p-invalid' : ''"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: label?.toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        @focus="openCalendar"
        @click="openCalendar"
      />
    </div>
    <label :for="id" class="top-0 text-xs bg-white dark:bg-zinc-900">{{ label }}</label>
  </FloatLabel>

  <div class="col-span-12">
    <small class="p-error" v-if="val.dateTime.$error" :data-testid="`${dataTestIdError}-error`">
      {{ val.dateTime.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { required } from "@/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";

const dateInput = ref<HTMLInputElement | null>(null);

const openCalendar = () => {
  if (dateInput.value) {
    dateInput.value.showPicker();
  }
};

onMounted(() => {
  if (dateInput.value) {
    dateInput.value.focus();
  }
});

const props = defineProps<{
  dateTime: string;
  id: string;
  label?: string;
  dataTestId?: string;
  dataTestIdError?: string;
}>();

const emit = defineEmits<{
  (e: "update:dateTime", value: string): void;
}>();

const { t } = useI18n();

const formatDateTimeLocal = (date: Date): string => {
  const yyyy = String(date.getFullYear()).padStart(4, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
};

const activeComputed = computed<string>({
  get: () => {
    return props.dateTime ? formatDateTimeLocal(new Date(props.dateTime)) : "";
  },
  set: (value) => {
    if (value) {
      const time = activeTimeComputed.value?.length > 0 ? new Date(activeTimeComputed.value) : new Date();
      const date = new Date(value);
      date.setHours(time.getHours(), time.getMinutes(), 0);

      emit("update:dateTime", date.toISOString());
    }
  },
});

const activeTimeComputed = computed<string>({
  get: () => {
    return props.dateTime ? props.dateTime : "";
  },
  set: (value) => {
    if (value) {
      const tempTime = value.split(":");
      const date = activeComputed.value?.length > 0 ? new Date(activeComputed.value) : new Date();
      date.setHours(parseInt(tempTime[0], 10), parseInt(tempTime[1], 10), 0);

      emit("update:dateTime", date.toISOString());
    }
  },
});

const rules = {
  dateTime: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
<style scoped lang="scss"></style>
