import { httpClient, type AuthHeaders } from "@cumulus/http";
import { DiscountSearchRequest } from "../../model/search/discount/DiscountSearchRequest";
import { DiscountSearchResponse } from "../../model/search/discount/DiscountSearchResponse";
import { ProductSearchResponse } from "@/discount/model/search/product/ProductSearchResponse";
import { ProductSearchRequest } from "@/discount/model/search/product/ProductSearchRequest";
import { CustomerSearchRequest } from "@/discount/model/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "@/discount/model/search/customer/CustomerSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH !== "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async discountSearch(
    authHeaders: AuthHeaders,
    request: DiscountSearchRequest,
  ): Promise<DiscountSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/discounts/search", request)
      .then(({ data }) => data);
  }

  public async productSearch(authHeaders: AuthHeaders, request: ProductSearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }

  public async customerSearch(
    authHeaders: AuthHeaders,
    request: CustomerSearchRequest,
  ): Promise<CustomerSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/customers/search", request)
      .then(({ data }) => data);
  }
}

const searchApi = new SearchApi();
export { searchApi };
