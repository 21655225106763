<template>
  <FloatLabel variant="on">
    <Select
      v-model="selectedManufacturerComputed"
      :options="allAvailableManufacturers"
      overlayClass="hidden"
      optionLabel="name"
      data-testid="c-discount-manufacturer-dropdown"
      class="w-full"
      @click="toggleManufacturerSearch"
      v-tooltip.bottom="{
        value: t('placeholder.select', {
          property: t('discount.manufacturer-group-level.manufacturer').toLowerCase(),
        }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="c-discount-manufacturer-dropdown">
      {{ t("discount.manufacturer-group-level.manufacturer") }}
    </label>
  </FloatLabel>
  <div v-show="showManufacturerSearch" class="c-manufacturer-dropdown">
    <div class="p-inputgroup c-minimal-inputtext c-minimal-inputgroup">
      <InputText
        ref="searchInputRef"
        class="w-full"
        v-model="manufacturerNameQuery"
        v-debounce:200="search"
        :placeholder="t('discount.manufacturer-group-level.manufacturer-search')"
        @keydown.enter.prevent="focusSearchResult"
        @keydown.tab="focusSearchResult"
        @keydown.esc="handleEscape($event)"
        aria-controls="manufacturer_overlay_panel"
        data-testid="discount-manufacturer-search"
      />
      <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
    </div>
    <ManufacturerSearchList
      ref="manufacturerSearchListRef"
      :manufacturers="manufacturers"
      :loading="loading"
      @manufacturerSelected="onManufacturerSelected"
    />
  </div>
</template>

<script setup lang="ts">
import { type ComponentPublicInstance, ref, nextTick, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Manufacturer } from "../../model/manufacturer/Manufacturer";
import ManufacturerSearchList from "./ManufacturerSearchList.vue";
import { useManufacturerService } from "../../api/manufacturer/ManufacturerService";

const props = defineProps<{
  manufacturerId: string;
  allAvailableManufacturers: Manufacturer[];
}>();

const emit = defineEmits<{
  (e: "manufacturerSelected", value: Manufacturer): void;
}>();

const { t } = useI18n();
const { getActiveManufacturersByName } = useManufacturerService();

const manufacturerNameQuery = ref<string>("");
const manufacturers = ref<Manufacturer[]>([]);
const manufacturerSearchListRef = ref<ComponentPublicInstance<HTMLInputElement>>();
const searchInputRef = ref();
const loading = ref<boolean>(false);
const showManufacturerSearch = ref<boolean>(false);

const selectedManufacturerComputed = computed<Manufacturer | null>({
  get: () => {
    return props.allAvailableManufacturers.find((manufacturer) => manufacturer.id === props.manufacturerId) || null;
  },
  set: (manufacturer: Manufacturer | null) => {
    if (manufacturer) {
      emit("manufacturerSelected", manufacturer);
    }
  },
});
const onManufacturerSelected = (manufacturer: Manufacturer) => {
  showManufacturerSearch.value = false;
  emit("manufacturerSelected", manufacturer);
};

const toggleManufacturerSearch = () => {
  showManufacturerSearch.value = !showManufacturerSearch.value;
  if (showManufacturerSearch.value) {
    nextTick(() => {
      searchInputRef.value.$el.focus();
    });
  }
};

const search = async () => {
  try {
    loading.value = true;
    const query = manufacturerNameQuery.value ? manufacturerNameQuery.value : "*";
    manufacturers.value = await getActiveManufacturersByName(query);
  } finally {
    loading.value = false;
  }
};

const focusSearchResult = () => {
  if (manufacturers.value.length > 0 && manufacturerSearchListRef.value) {
    nextTick(() => {
      const firstTableRow = manufacturerSearchListRef.value?.$el.querySelector("tbody tr:first-of-type");
      if (firstTableRow) {
        firstTableRow.focus();
      }
    });
  }
};

const handleEscape = (event: KeyboardEvent) => {
  if (manufacturerNameQuery.value !== "") {
    manufacturerNameQuery.value = "";
    event.stopPropagation();
  }
};

onMounted(async () => {
  await search();
});
</script>

<style scoped lang="scss">
.c-manufacturer-dropdown {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--surface-section);
  border-top: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.dark .c-manufacturer-dropdown {
  border: 1px solid var(--p-surface-600);
  padding: 0px;
  background-color: var(--p-surface-950);
}
</style>
