<template>
  <div class="mt-4">
    <FloatLabelInput
      :label="t('discount.name')"
      v-model:value="nameComputed"
      id="discount-name"
      :focusInput="focusInput"
      :placeholder="t('placeholder.type', { property: t('discount.name').toLowerCase() })"
      dataTestId="discount-name"
      :maxlength="8000"
    />
    <small class="p-error" v-if="val.name.$error" data-testid="discount-name-error">
      {{ val.name.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { required } from "../../i18n-validators";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  name: string;
  focusInput: boolean;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const { t } = useI18n();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value: string) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, props);

const inputRef = ref();
const focus = () => {
  if (inputRef.value) {
    inputRef.value.$el.focus();
  }
};

onMounted(focus);
</script>
