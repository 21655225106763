import { SearchProduct } from "../search/product/SearchProduct";
import { DiscountRule } from "./DiscountRule";

export class DiscountProduct {
  id = "";
  discountRule: DiscountRule = DiscountRule.FixedPrice;
  fixedPrice = 0;
  discountPercentage = 0;

  public static createFromSearchProduct(product: SearchProduct, discountRule: DiscountRule) {
    const discountProduct = new DiscountProduct();
    discountProduct.id = product.id;
    discountProduct.discountRule = discountRule;
    return discountProduct;
  }
}
