{  
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før",
    "today-date": "{property} må være dagens dato eller senere",
    "to-time-must-be-later-than-from-time": "Til tid må være senere enn fra tid",
    "to-date-must-be-later-than-from-date": "Til dato må være senere enn fra dato",
    "summary": "Validering feilet",
    "detail": "Rett alle rød felter og prøv igjen"
  },
  "error-detail": {
    "error-occured": "En feil oppstod",
    "unknown-error": "Ukjent feil",
    "server-error": "Server feil",
    "not-found": "Ikke funnet",
    "bad-request": "Feil i forespørsel"
  },
  "common": {
    "save": "Lagre",
    "cancel": "Avbryt",
    "delete": "Slett",
    "add": "Legg til",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "hide-filter": "Skjul filter",
    "show-filter": "Vis filter",
    "clear-filter": "Tøm filter",
    "filter": "Filter",
    "from-date": "Fra dato",
    "to-date": "Til dato",
    "status": "Status",
    "type": "Type",
    "general": "Generell",
    "open-panels": "Åpne paneler",
    "close-panels": "Lukk paneler",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard":"Forkast",
    "delete-confirm": "Vil du slette dette elementet?",
    "save-tooltip": "Ingen endringer"
  }
}
