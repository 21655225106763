import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { discountApi } from "./DiscountApi";
import { Discount } from "@/discount/model/discount/Discount";

export function useDiscountService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getDiscountById = async (id: string): Promise<Discount> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await discountApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getDiscountByName = async (name: string): Promise<Discount | null> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await discountApi.getByName(authHeaders, name);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addDiscount = async (discount: Discount): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await discountApi.add(authHeaders, discount);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateDiscount = async (discount: Discount): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await discountApi.update(authHeaders, discount);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteDiscount = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await discountApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { addDiscount, updateDiscount, deleteDiscount, getDiscountById, getDiscountByName };
}
