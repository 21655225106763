export const DiscountLinesColumns = {
  Registered: "registered",
  RegisteredByEmployeeInitials: "registeredByEmployeeInitials",
  Name: "name",
  Description: "description",
  DiscountType: "discountType",
  DiscountRule: "discountRule",
  ActiveFrom: "activeFrom",
  ActiveTo: "activeTo",
  Active: "active",
} as const;
