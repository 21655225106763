import { Ref } from "vue";
import { Discount } from "../model/discount/Discount";
import { DiscountProduct } from "../model/discount/DiscountProduct";
import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { SearchProduct } from "../model/search/product/SearchProduct";

export function discountProductFunctions(discount: Ref<Discount>, selectedProducts: Ref<ProductWithDiscountInfo[]>) {
  const addProductToDiscountList = (searchProduct: SearchProduct) => {
    const existingProduct = discount.value.products.find((x: DiscountProduct) => x.id === searchProduct.id);

    if (existingProduct) return;
    if (discount.value.discountRule === null) return;

    const discountProduct = DiscountProduct.createFromSearchProduct(searchProduct, discount.value.discountRule);
    const discountProductList = ProductWithDiscountInfo.createFromSearchProduct(
      searchProduct,
      discount.value.discountRule
    );

    discount.value.products.push(discountProduct);
    selectedProducts.value.push(discountProductList);
  };

  const updateContributionMarginOnProduct = (product: ProductWithDiscountInfo) => {
    const contributionMargin =
      product.fixedPrice > 0 ? ((product.fixedPrice - product.costPrice) / product.fixedPrice) * 100 : 0;
    product.contributionMargin = contributionMargin;
  };

  const removeProductFromDiscount = (product: ProductWithDiscountInfo) => {
    discount.value.products = discount.value.products.filter((x: DiscountProduct) => x.id !== product.id);
    selectedProducts.value = selectedProducts.value.filter((x: ProductWithDiscountInfo) => x.id !== product.id);
  };

  const updateDiscountPercentageOnProduct = (product: ProductWithDiscountInfo) => {
    const updatedDiscountProduct = discount.value.products.find((x: DiscountProduct) => x.id === product.id);
    const selectedProduct = selectedProducts.value.find((x: ProductWithDiscountInfo) => x.id === product.id);

    if (updatedDiscountProduct === undefined || selectedProduct === undefined) return;

    updatedDiscountProduct.discountPercentage = product.discountPercentage;
    selectedProduct.discountPercentage = product.discountPercentage;

    const fixedPrice = selectedProduct.listPrice - (selectedProduct.listPrice * product.discountPercentage) / 100;

    updatedDiscountProduct.fixedPrice = fixedPrice;
    selectedProduct.fixedPrice = fixedPrice;

    updateContributionMarginOnProduct(selectedProduct);
  };

  const updateFixedPriceOnProduct = (product: ProductWithDiscountInfo) => {
    const updatedProduct = discount.value.products.find((x: DiscountProduct) => x.id === product.id);
    const selectedProduct = selectedProducts.value.find((x: ProductWithDiscountInfo) => x.id === product.id);

    if (updatedProduct === undefined || selectedProduct === undefined) return;

    updatedProduct.fixedPrice = product.fixedPrice;
    selectedProduct.fixedPrice = product.fixedPrice;

    const discountPercentage =
      selectedProduct.listPrice > 0
        ? ((selectedProduct.listPrice - product.fixedPrice) / selectedProduct.listPrice) * 100
        : 0;

    updatedProduct.discountPercentage = discountPercentage;
    selectedProduct.discountPercentage = discountPercentage;

    updateContributionMarginOnProduct(selectedProduct);
  };

  return {
    addProductToDiscountList,
    removeProductFromDiscount,
    updateDiscountPercentageOnProduct,
    updateFixedPriceOnProduct,
  };
}
