import { ref } from "vue";
import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../api/ErrorHandler";
import { ProductHierarchy } from "../model/product/ProductHierarchy";
import { productApi } from "../api/product/ProductApi";

export const useProductHierarchyStore = defineStore("product-hierarchy", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productHierarchies = ref<ProductHierarchy[]>([]);

  const getAllProductHierarchies = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      productHierarchies.value = await productApi.getProductHierarchies(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    productHierarchies,
    getAllProductHierarchies,
  };
});
