import { createRouter, createWebHistory } from "vue-router";
import { registerGuard } from "@cumulus/event-bus";
import { discountRoutes } from "@/discount/routes";
import { mainRoutes } from "./routes";

const routes = [...mainRoutes, ...discountRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active",
});

registerGuard(router);

export default router;
