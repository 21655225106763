import { Manufacturer } from "@/discount/model/manufacturer/Manufacturer";
import { httpClient, AuthHeaders } from "@cumulus/http";

class ManufacturerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/manufacturers`
        : `${import.meta.env.VITE_APP_API_URL as string}/manufacturers`;
  }

  public async getActiveManufacturersByName(authHeaders: AuthHeaders, name: string): Promise<Manufacturer[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/by-name/${name}`)
      .then((response) => response.data);
  }

  public async getManufacturersByIds(authHeaders: AuthHeaders, ids: string[]): Promise<Manufacturer[]> {
    return await httpClient(this.uri, authHeaders)
      .post("/by-ids", ids)
      .then((response) => response.data);
  }
}

export const manufacturerApi = new ManufacturerApi();
