<template>
  <ManufacturerGroupLevelModal
    v-if="manufacturerGroupLevelModalVisible"
    v-model:visible="manufacturerGroupLevelModalVisible"
    :selectedManufacturerGroupLevelInfo="selectedManufaturerHierarchy"
    @manufacturerGroupLevelAdded="addManufacturerGroupLevelDiscount"
    @manufacturerGroupLevelUpdated="updateManufacturerGroupLevelDiscount"
    @manufacturerGroupLevelModalClosed="manufacturerGroupLevelModalVisible = false"
  />
  <div class="col-span-12">
    <ManufacturerGroupLevelList
      :manufacturerGroupLevelInfos="manufacturerGroupLevelInfos"
      :loading="loading"
      @editManufacturerGroupLevelClicked="openManufacturerGroupLevelModal"
      @confirmRemoveManufacturerGroupLevelClicked="removeManufacturerGroupLevelDiscount"
      @discountPercentageUpdated="updateDiscountPercentage"
    />
  </div>
  <div class="col-span-12">
    <div class="flex justify-end mt-6">
      <PrimeButton
        :label="t('discount.manufacturer-group-level.add-manufacturer-group-level-discount')"
        @click="openManufacturerGroupLevelModal"
        icon="pi pi-plus"
        data-testid="c-add-new-manufacturer-group-level-discount"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ManufacturerGroupLevelModal from "./ManufacturerGroupLevelModal.vue";
import ManufacturerGroupLevelList from "./ManufacturerGroupLevelList.vue";
import { ref, watch } from "vue";
import { DiscountManufacturerGroupLevel } from "../../model/discount/DiscountManufacturerGroupLevel";
import { ManufacturerGroupLevelInfo } from "../../model/manufacturer/ManufacturerGroupLevelInfo";
import { useDiscountManufacturerGroupLevelFunctions } from "../../utils/discountManufacturerGroupLevelFunctions";
import { useI18n } from "vue-i18n";

const manufacturerGroupLevelDiscounts = defineModel<DiscountManufacturerGroupLevel[]>(
  "manufacturerGroupLevelDiscounts",
  {
    required: true,
  }
);

const manufacturerGroupLevelInfos = ref<ManufacturerGroupLevelInfo[]>([]);
const selectedManufaturerHierarchy = ref<ManufacturerGroupLevelInfo | null>(null);
const { t } = useI18n();

const props = defineProps<{
  existingManufacturerGroupLevelInfos?: ManufacturerGroupLevelInfo[];
  loading: boolean;
}>();

const {
  addManufacturerGroupLevelDiscount,
  removeManufacturerGroupLevelDiscount,
  updateManufacturerGroupLevelDiscount,
  updateDiscountPercentage,
} = useDiscountManufacturerGroupLevelFunctions(manufacturerGroupLevelDiscounts, manufacturerGroupLevelInfos);

const manufacturerGroupLevelModalVisible = ref(false);

const openManufacturerGroupLevelModal = (manufacturerGroupLevel: ManufacturerGroupLevelInfo) => {
  if (manufacturerGroupLevel instanceof Event) {
    selectedManufaturerHierarchy.value = null;
  } else {
    selectedManufaturerHierarchy.value = manufacturerGroupLevel;
  }
  manufacturerGroupLevelModalVisible.value = true;
};

watch(
  () => props.existingManufacturerGroupLevelInfos,
  (newValue) => {
    manufacturerGroupLevelInfos.value = newValue ?? [];
  }
);

watch(
  () => manufacturerGroupLevelDiscounts.value,
  (newValue) => {
    if (newValue.length === 0) {
      manufacturerGroupLevelInfos.value = [];
    }
  },
  { deep: true }
);
</script>
