<template>
  <div v-click-outside="closeDropdown">
    <FloatLabelDropdownPanel
      :selectedItemName="manufacturerComputed.name"
      :label="t('discount.manufacturer-group-level.manufacturer')"
      :selectLabel="
        t('placeholder.select', { property: t('discount.manufacturer-group-level.manufacturer').toLowerCase() })
      "
      @toggleDropdownPanel="toggleManufacturerSearch"
      data-testid="c-discount-manufacturer-dropdown"
    />

    <div v-show="showManufacturerSearch" class="c-manufacturer-dropdown">
      <div class="p-inputgroup c-minimal-inputtext c-minimal-inputgroup">
        <InputText
          ref="searchInputRef"
          class="w-full"
          v-model="manufacturerNameQuery"
          v-debounce:200="search"
          :placeholder="t('discount.manufacturer-group-level.manufacturer-search')"
          @keydown.enter.prevent="focusSearchResult"
          @keydown.tab="focusSearchResult"
          @keydown.esc="handleEscape($event)"
          aria-controls="manufacturer_overlay_panel"
          data-testid="discount-manufacturer-search"
        />
        <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
      </div>
      <ManufacturerSearchList
        ref="manufacturerSearchListRef"
        :manufacturers="manufacturers"
        :loading="loading"
        @manufacturerSelected="onManufacturerSelected"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, ref, nextTick, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { Manufacturer } from "../../model/manufacturer/Manufacturer";
import ManufacturerSearchList from "./ManufacturerSearchList.vue";
import { useManufacturerService } from "../../api/manufacturer/ManufacturerService";

const props = defineProps<{
  manufacturerId: string;
}>();

const emit = defineEmits<{
  (e: "manufacturerSelected", value: Manufacturer): void;
}>();

const { t } = useI18n();
const { getActiveManufacturersByName } = useManufacturerService();

const manufacturerNameQuery = ref<string>("");
const manufacturers = ref<Manufacturer[]>([]);
const manufacturerSearchListRef = ref<ComponentPublicInstance<HTMLInputElement>>();
const searchInputRef = ref();
const loading = ref<boolean>(false);
const showManufacturerSearch = ref<boolean>(false);

const manufacturerComputed = computed<Manufacturer>(() => {
  return manufacturers.value.find((manufacturer) => manufacturer.id === props.manufacturerId) || new Manufacturer();
});

const onManufacturerSelected = (manufacturer: Manufacturer) => {
  showManufacturerSearch.value = false;
  emit("manufacturerSelected", manufacturer);
};

const closeDropdown = () => {
  showManufacturerSearch.value = false;
};

const toggleManufacturerSearch = () => {
  showManufacturerSearch.value = !showManufacturerSearch.value;
  if (showManufacturerSearch.value) {
    nextTick(() => {
      searchInputRef.value.$el.focus();
    });
  }
};

const search = async () => {
  try {
    loading.value = true;
    const query = manufacturerNameQuery.value ? manufacturerNameQuery.value : "*";
    manufacturers.value = await getActiveManufacturersByName(query);
  } finally {
    loading.value = false;
  }
};

const focusSearchResult = () => {
  if (manufacturers.value.length > 0 && manufacturerSearchListRef.value) {
    nextTick(() => {
      const firstTableRow = manufacturerSearchListRef.value?.$el.querySelector("tbody tr:first-of-type");
      if (firstTableRow) {
        firstTableRow.focus();
      }
    });
  }
};

const handleEscape = (event: KeyboardEvent) => {
  if (manufacturerNameQuery.value !== "") {
    manufacturerNameQuery.value = "";
    event.stopPropagation();
  }
};

onMounted(async () => {
  await search();
});
</script>

<style scoped lang="scss">
.c-manufacturer-dropdown {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--surface-section);
  border-top: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}
</style>
