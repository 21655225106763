<template>
  <DataTable
    :value="manufacturerGroupLevelInfos"
    stripedRows
    dataKey="id"
    ref="manufacturerListRef"
    class="c-datatable"
    responsiveLayout="scroll"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    editMode="cell"
    data-testid="discount-manufacturer-list"
  >
    <Column field="manufacturerName" :header="t('discount.manufacturer-group-level.manufacturer')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-manufacturer-list-name-${index}`">{{ data[field as keyof typeof data] }}</span>
      </template>
    </Column>
    <Column header="Group levels">
      <template #body="{ data, index }">
        <span :data-testid="`discount-manufacturer-list-group-levels-${index}`">{{ getGroupLevelNames(data) }}</span>
      </template>
    </Column>
    <Column field="discountPercentage" :header="t('discount.manufacturer-group-level.discount') + ' %'" class="w-28">
      <template #body="{ data, field, index }">
        <DiscountInput
          :id="`discount-manufacturer-discount-percentage-input-${index}`"
          inputClass="w-20"
          :minValue="0"
          :maxValue="100"
          :digits="2"
          :value="data[field as keyof typeof data]"
          :allowEmpty="false"
          @valueUpdated="onDiscountPercentageUpdated(data, $event)"
        /> </template
    ></Column>
    <Column class="text-center">
      <template #body="{ data, index }">
        <Button
          icon="pi pi-ellipsis-h"
          text
          rounded
          @click="toggleMenu($event, data)"
          :data-testid="`c-menu-button-${index}`"
        />
        <TieredMenu ref="tm" :model="menuItems" data-testid="c-manufacturer-group-level-menu" popup />
      </template>
    </Column>

    <template #empty>
      <span data-testid="discount-manufacturer-list-no-found">{{
        t("discount.manufacturer-group-level.no-discounts-found")
      }}</span>
    </template>
  </DataTable>

  <ConfirmPopup data-testid="discount-manufacturer-remove-confirm"></ConfirmPopup>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import DiscountInput from "../DiscountInput.vue";
import { ref } from "vue";
import { ManufacturerGroupLevelInfo } from "../../model/manufacturer/ManufacturerGroupLevelInfo";
import { useConfirm } from "primevue/useconfirm";
import type { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";

const tm = ref();
const confirm = useConfirm();

defineProps<{
  manufacturerGroupLevelInfos: ManufacturerGroupLevelInfo[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "editManufacturerGroupLevelClicked", manufacturerGroupLevel: ManufacturerGroupLevelInfo): void;
  (e: "confirmRemoveManufacturerGroupLevelClicked", manufacturerGroupLevel: ManufacturerGroupLevelInfo): void;
  (
    e: "discountPercentageUpdated",
    manufacturerGroupLevel: ManufacturerGroupLevelInfo,
    discountPercentage: number,
  ): void;
}>();

const selectedManufacturerGroupLevel = ref<ManufacturerGroupLevelInfo>(new ManufacturerGroupLevelInfo());

const manufacturerListRef = ref();

const toggleMenu = (event: Event, mgl: ManufacturerGroupLevelInfo) => {
  selectedManufacturerGroupLevel.value = mgl;
  tm.value.toggle(event);
};

const onDiscountPercentageUpdated = (
  manufacturerGroupLevel: ManufacturerGroupLevelInfo,
  discountPercentage: number,
) => {
  emit("discountPercentageUpdated", manufacturerGroupLevel, discountPercentage);
};

const menuItems = ref<MenuItem[]>([
  {
    label: "Edit",
    icon: "pi pi-file-edit",
    command: () => {
      emit("editManufacturerGroupLevelClicked", selectedManufacturerGroupLevel.value);
    },
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    command: (event: MenuItemCommandEvent) => {
      onConfirmDelete(event.originalEvent);
    },
  },
]);

const getGroupLevelNames = (manufacturerGroupLevel: ManufacturerGroupLevelInfo): string => {
  return [
    manufacturerGroupLevel.groupLevel1.name,
    manufacturerGroupLevel.groupLevel2.name,
    manufacturerGroupLevel.groupLevel3.name,
    manufacturerGroupLevel.groupLevel4.name,
    manufacturerGroupLevel.groupLevel5.name,
  ]
    .filter((name) => name)
    .join(", ");
};

const { t } = useI18n();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("confirmRemoveManufacturerGroupLevelClicked", selectedManufacturerGroupLevel.value);
    },
  });
};
</script>

<style scoped lang="scss">
.c-delete-btn {
  color: var(--delete-btn-bg);
}
</style>
