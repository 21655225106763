<template>
  <div class="c-minimal-inputtext c-minimal-inputgroup ml-8">
    <div class="p-inputgroup">
      <InputText
        ref="searchInput"
        class="input-search-product w-full"
        v-model="query"
        v-debounce:200="search"
        :placeholder="t('discount.product.search')"
        type="text"
        @keydown.enter.prevent="focusSearchResult"
        @keydown.tab="focusSearchResult"
        @keydown.esc="handleEscape($event)"
        @input="showSearchOverlayPanel($event)"
        @focus="showSearchOverlayPanel($event)"
        aria-controls="product_overlay_panel"
        data-testid="discount-product-search"
      />
      <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
    </div>
  </div>

  <Popover
    ref="overlayPanelRef"
    appendTo="body"
    :showCloseIcon="false"
    :dismissable="searchResultPanelDismissable"
    id="product_overlay_panel"
    :breakpoints="{ '1250px': '100vw' }"
    :style="{ width: '1250px' }"
    @keydown.esc.stop="hideSearchOverlayPanel"
  >
    <ProductSearchList
      ref="productListRef"
      :products="products"
      :loading="loading"
      @productSelected="onProductSelected"
    />
    <small v-if="noDiscountRuleSelected" class="p-error">
      {{ t("discount.product.discount-rule-must-be-selected") }}
    </small>
  </Popover>
</template>

<script setup lang="ts">
import { type ComponentPublicInstance, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSearchService } from "../api/search/SearchService";
import { SearchProduct } from "../model/search/product/SearchProduct";
import { ProductSearchResponse } from "../model/search/product/ProductSearchResponse";
import { ProductSearchRequest } from "../model/search/product/ProductSearchRequest";
import ProductSearchList from "./ProductSearchList.vue";
import { DiscountRule } from "../model/discount/DiscountRule";
const props = defineProps<{
  discountRule: DiscountRule | null;
}>();

const emit = defineEmits<{
  (e: "productSelected", value: SearchProduct): void;
}>();

const { t } = useI18n();
const { productSearch } = useSearchService();

const query = ref<string>("");
const products = ref<SearchProduct[]>([]);
const loading = ref<boolean>(false);
const overlayPanelRef = ref();
const searchResultPanelDismissable = ref(true);
const selectedProduct = ref<SearchProduct | null>(null);
const productListRef = ref<ComponentPublicInstance<HTMLInputElement>>();
const noDiscountRuleSelected = ref(false);

const onProductSelected = (product: SearchProduct) => {
  if (!props.discountRule) {
    noDiscountRuleSelected.value = true;
    return;
  }
  noDiscountRuleSelected.value = false;
  emit("productSelected", product);
  hideSearchOverlayPanel();
};

const search = async () => {
  try {
    loading.value = true;
    const request = new ProductSearchRequest(query.value);
    const response: ProductSearchResponse = await productSearch(request);
    products.value = response.products;
  } finally {
    loading.value = false;
  }
};

const focusSearchResult = () => {
  if (products.value.length > 0 && productListRef.value) {
    nextTick(() => {
      const firstTableRow = productListRef.value?.$el.querySelector("tbody tr:first-of-type");
      if (firstTableRow) {
        firstTableRow.focus();
      }
    });
  }
};

const handleEscape = (event: KeyboardEvent) => {
  if (query.value !== "") {
    query.value = "";
    hideSearchOverlayPanel();
    event.stopPropagation();
  }
};

const hideSearchOverlayPanel = () => {
  selectedProduct.value = null;
  overlayPanelRef.value.hide();
};

const showSearchOverlayPanel = (event: Event) => {
  if (query.value === "") {
    overlayPanelRef.value.hide();
    return;
  }
  overlayPanelRef.value.show({ currentTarget: event.target });
};
</script>
<style scoped lang="scss">
:deep(input.p-inputtext.p-component) {
  border: none;
  border-bottom: var(--floating-input-border);
  border-radius: 0;
  padding: 0.25rem;
}
</style>
