<template>
  <FloatLabel variant="on">
    <div class="c-datepicker-wrapper">
      <input
        type="date"
        :data-testid="dataTestId"
        :id="id"
        max="9999-12-31"
        v-model="activeComputed"
        class="c-date w-full text-xs !pl-2 dark:bg-zinc-900"
        :class="val.dateTime.$error ? 'p-invalid' : ''"
        :disabled="disabled"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: label?.toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        @keydown="handleKeydown"
      />
    </div>
    <label :for="id" class="top-0 text-xs bg-white dark:bg-zinc-900">{{ label }}</label>
  </FloatLabel>

  <div class="col-span-12">
    <small class="p-error" v-if="val.dateTime.$error" :data-testid="`${dataTestIdError}-error`">
      {{ val.dateTime.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "vue-debounce";

const props = defineProps<{
  dateTime: string;
  id: string;
  label?: string;
  dataTestId?: string;
  dataTestIdError?: string;
  disabled?: boolean;
  debounceTime?: number;
}>();

const emit = defineEmits<{
  (e: "update:dateTime", value: string): void;
  (e: "keydown", event: KeyboardEvent): void;
}>();

const { t } = useI18n();

const formatDateLocal = (date: Date): string => {
  const yyyy = String(date.getFullYear()).padStart(4, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

const debouncedEmit = debounce((value: string) => {
  emit("update:dateTime", value);
}, props.debounceTime || 300);

const activeComputed = computed<string>({
  get: () => {
    return props.dateTime ? formatDateLocal(new Date(props.dateTime)) : "";
  },
  set: (value) => {
    if (value) {
      const date = new Date(value);
      debouncedEmit(date.toISOString().split("T")[0]);
    }
  },
});

const handleKeydown = (event: KeyboardEvent) => {
  emit("keydown", event);
};

const rules = {
  dateTime: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
