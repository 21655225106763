import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { ProductSearchRequest } from "../../model/search/product/ProductSearchRequest";
import { ProductSearchResponse } from "../../model/search/product/ProductSearchResponse";
import { useErrorHandler } from "../ErrorHandler";
import { DiscountSearchRequest } from "@/discount/model/search/discount/DiscountSearchRequest";
import { DiscountSearchResponse } from "@/discount/model/search/discount/DiscountSearchResponse";
import { CustomerSearchRequest } from "@/discount/model/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "@/discount/model/search/customer/CustomerSearchResponse";

export function useSearchService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productSearch = async (request: ProductSearchRequest): Promise<ProductSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.productSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new ProductSearchResponse();
    }
  };

  const discountSearch = async (request: DiscountSearchRequest): Promise<DiscountSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.discountSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new DiscountSearchResponse();
    }
  };

  const customerSearch = async (request: CustomerSearchRequest): Promise<CustomerSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.customerSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new CustomerSearchResponse();
    }
  };

  return { productSearch, discountSearch, customerSearch };
}
