import { ref } from "vue";
import { LanguageIsoType } from "../model/product/LanguageIsoType";
import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";

export const useLanguageStore = defineStore("language", () => {
  const { getUser } = useAuth();

  const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

  const getLanguageIso = async () => {
    const user = await getUser();
    const employee = user?.getEmployee();
    setLanguageIso((employee?.languageCode as LanguageIsoType) || LanguageIsoType.English);
  };

  const setLanguageIso = (value: LanguageIsoType) => {
    languageIso.value = value;
  };

  return {
    languageIso,
    getLanguageIso,
  };
});
