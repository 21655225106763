<template>
  <ul class="c-chips-container" v-if="hasActiveFilters" data-testid="c-active-filters-container">
    <li class="c-chips-clear-filter">
      <Button
        type="button"
        :label="t('common.clear-filter')"
        icon="pi pi-times-circle"
        @click="clearFilters"
        class="text-sm mr-4 p-1"
        data-testid="c-clear-active-filters-btn"
        rounded
      />
    </li>

    <ActiveFilterChip
      v-if="filters.registeredFromDate !== null"
      :filterValue="d(filters.registeredFromDate)"
      filterType="registeredFromDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-registered-from-date"
      :label="t('discount.registered-from')"
    />

    <ActiveFilterChip
      v-if="filters.registeredToDate !== null"
      :filterValue="d(filters.registeredToDate)"
      filterType="registeredToDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-registered-to-date"
      :label="t('discount.registered-to')"
    />

    <ActiveFilterChip
      v-if="filters.activeFromDate !== null"
      :filterValue="d(filters.activeFromDate)"
      filterType="activeFromDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-active-from-date"
      :label="t('discount.active-from')"
    />

    <ActiveFilterChip
      v-if="filters.activeToDate !== null"
      :filterValue="d(filters.activeToDate)"
      filterType="activeToDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-active-to-date"
      :label="t('discount.active-to')"
    />

    <ActiveFilterChip
      v-if="filters.discountRules.length > 0"
      :filterValue="filters.discountRules.map((rule: DiscountRule) => t(`discount.rule.${rule.toLowerCase()}`))"
      filterType="discountRules"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-rule"
    />

    <ActiveFilterChip
      v-if="filters.discountStatuses.length > 0"
      :filterValue="
        filters.discountStatuses.map((status: DiscountStatus) => t(`discount.status.${status.toLowerCase()}`))
      "
      filterType="discountStatuses"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-discount-status"
    />
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import ActiveFilterChip from "./ActiveFilterChip.vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import { DiscountRule } from "../model/discount/DiscountRule";
import { DiscountStatus } from "../model/search/discount/DiscountStatus";

const props = defineProps<{
  filters: DiscountSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: DiscountSearchFilters): void;
}>();

const { t, d } = useI18n();

const removeFilter = (filterType: keyof DiscountSearchFilters, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (
    filterType === "registeredFromDate" ||
    filterType === "registeredToDate" ||
    filterType === "activeFromDate" ||
    filterType === "activeToDate"
  ) {
    filters[filterType] = null;
  }

  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.registeredFromDate !== null ||
    props.filters.registeredToDate !== null ||
    props.filters.activeFromDate !== null ||
    props.filters.activeToDate !== null ||
    props.filters.discountRules.length > 0 ||
    props.filters.discountStatuses.length > 0
  );
});

const clearFilters = () => {
  const filters = new DiscountSearchFilters();
  emit("update:filters", filters);
};
</script>

<style scoped lang="scss">
.c-chips-container {
  padding: 0.25rem 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
