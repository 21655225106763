<template>
  <div class="mt-4">
    <FloatLabelInput
      :label="t('discount.description')"
      v-model:value="descriptionComputed"
      id="discount-description"
      :placeholder="t('placeholder.type', { property: t('discount.description').toLowerCase() })"
      dataTestId="discount-description"
      :maxlength="8000"
    />
    <small class="p-error" v-if="val.description.$error" data-testid="discount-description-error">
      {{ val.description.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "../../i18n-validators";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const { t } = useI18n();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value: string) => {
    emit("update:description", value);
  },
});

const rules = {
  description: {
    required: required,
  },
};

const val = useVuelidate(rules, props);
</script>
