<template>
  <DataTable
    :value="products"
    stripedRows
    dataKey="id"
    ref="productListRef"
    class="c-datatable"
    responsiveLayout="scroll"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="discount-product-list"
  >
    <Column field="discountRule" :header="t('discount.rule.label')" class="w-60">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-product-list-rule-${index}`">{{
          t(`discount.rule.${data[field].toLowerCase()}`)
        }}</span>
      </template>
    </Column>
    <Column field="productNumber" :header="t('discount.product.product-number')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-product-list-product-number-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="name" :header="t('discount.product.product-name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-product-list-name-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="listPrice" :header="t('discount.product.list-price')">
      <template #body="{ data, field, index }">
        <div class="text-right" :data-testid="`discount-product-list-price-${index}`">
          {{ n(data[field], "decimal") }}
        </div>
      </template>
    </Column>
    <Column field="fixedPrice" :header="t('discount.product.product-price')" class="w-36">
      <template #body="{ data, field, index }">
        <DiscountInput
          v-if="discountRule === DiscountRule.FixedPrice"
          :id="`discount-product-list-fixed-price-input-${index}`"
          inputClass="w-24"
          :minValue="0"
          :digits="2"
          :value="data[field]"
          :allowEmpty="false"
          @valueUpdated="updateFixedPrice($event, data)"
        />
        <div v-else class="text-right">
          <span :data-testid="`discount-product-list-fixed-price-${index}`">{{ n(data[field], "decimal") }}</span>
        </div>
      </template>
    </Column>
    <Column field="costPrice" :header="t('discount.product.cost-price')">
      <template #body="{ data, field, index }">
        <div class="text-right" :data-testid="`discount-product-list-cost-price-${index}`">
          {{ n(data[field], "decimal") }}
        </div>
      </template>
    </Column>
    <Column field="discountPercentage" :header="t('discount.product.discount') + ' %'" class="w-28">
      <template #body="{ data, field, index }">
        <DiscountInput
          v-if="discountRule === DiscountRule.DiscountOnListPrice"
          :id="`discount-product-list-discount-percentage-input-${index}`"
          inputClass="w-16"
          :minValue="0"
          :maxValue="100"
          :digits="2"
          :value="data[field]"
          :allowEmpty="false"
          @valueUpdated="updateDiscountPercentage($event, data)"
        />
        <div v-else class="text-right" :data-testid="`discount-product-list-discount-percentage-${index}`">
          {{ n(data[field], "decimal") + "%" }}
        </div>
      </template></Column
    >
    <Column field="contributionMargin" :header="t('discount.product.contribution-margin') + ' %'">
      <template #body="{ data, field, index }"
        ><div class="text-right" :data-testid="`discount-product-list-contribution-margin-${index}`">
          {{ n(data[field], "decimal") + "%" }}
        </div></template
      >
    </Column>
    <Column>
      <template #header>
        <i class="pi pi-trash c-delete-btn ml-3" />
      </template>

      <template #body="{ data, index }">
        <PrimeButton
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`product-delete-${index}`"
          @click="onConfirmDelete($event, data)"
        />
      </template>
    </Column>

    <template #empty>
      <span data-testid="discount-product-list-no-found">{{ t("discount.product.no-found") }}</span>
    </template>
  </DataTable>
  <ConfirmPopup data-testid="discount-product-remove-confirm"></ConfirmPopup>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ProductWithDiscountInfo } from "../model/discount/ProductWithDiscountInfo";
import { DiscountRule } from "../model/discount/DiscountRule";
import DiscountInput from "./DiscountInput.vue";
import { ref, watch, nextTick } from "vue";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const props = defineProps<{
  products: ProductWithDiscountInfo[];
  discountRule: DiscountRule | null;
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "fixedPriceUpdated", value: ProductWithDiscountInfo): void;
  (e: "discountPercentageUpdated", value: ProductWithDiscountInfo): void;
  (e: "removeProductClicked", value: ProductWithDiscountInfo): void;
}>();

const { t, n } = useI18n();
const productListRef = ref();

const updateFixedPrice = (value: number, discountProduct: ProductWithDiscountInfo) => {
  emit("fixedPriceUpdated", { ...discountProduct, fixedPrice: value });
};

const updateDiscountPercentage = (value: number, discountProduct: ProductWithDiscountInfo) => {
  emit("discountPercentageUpdated", { ...discountProduct, discountPercentage: value });
};

const focusProductPriceInput = () => {
  if (props.products.length > 0) {
    productListRef.value.$el.querySelector("tbody tr:last-of-type input").focus();
  }
};
watch(
  () => props.products.length,
  () => {
    nextTick(() => {
      focusProductPriceInput();
    });
  }
);

const onConfirmDelete = (event: Event, product: ProductWithDiscountInfo) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("removeProductClicked", product);
    },
  });
};
</script>

<style scoped lang="scss">
.c-delete-btn {
  color: var(--delete-btn-bg);
}
</style>
