import { DiscountRule } from "../../discount/DiscountRule";
import { DiscountStatus } from "./DiscountStatus";

export class DiscountSearchFilters {
  activeFromDate: string | null = null;
  activeToDate: string | null = null;
  registeredFromDate: string | null = null;
  registeredToDate: string | null = null;
  discountRules: DiscountRule[] = [];
  discountStatuses: DiscountStatus[] = [];

  public equals = (other: DiscountSearchFilters): boolean => {
    return (
      this.activeFromDate === other.activeFromDate &&
      this.activeToDate === other.activeToDate &&
      this.registeredFromDate === other.registeredFromDate &&
      this.registeredToDate === other.registeredToDate &&
      this.discountRules === other.discountRules &&
      this.discountStatuses === other.discountStatuses
    );
  };
}
