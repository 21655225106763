import { ProductGroupNameByLanguage } from "../product/ProductGroupNameByLanguage";
import { v4 as uuidv4 } from "uuid";
import { NIL as emptyUuid } from "uuid";

export class ManufacturerGroupLevelInfo {
  id = uuidv4();
  manufacturerId = emptyUuid;
  manufacturerName = "";
  groupLevel1 = new ProductGroupNameByLanguage();
  groupLevel2 = new ProductGroupNameByLanguage();
  groupLevel3 = new ProductGroupNameByLanguage();
  groupLevel4 = new ProductGroupNameByLanguage();
  groupLevel5 = new ProductGroupNameByLanguage();
  discountPercentage = 0;
}
