import { ManufacturerGroupLevelInfo } from "../manufacturer/ManufacturerGroupLevelInfo";
import { v4 as uuidv4 } from "uuid";
import { NIL as emptyUuid } from "uuid";

export class DiscountManufacturerGroupLevel {
  id: string = uuidv4();
  manufacturerId: string = emptyUuid;
  groupLevel1Id: string = emptyUuid;
  groupLevel2Id: string = emptyUuid;
  groupLevel3Id: string = emptyUuid;
  groupLevel4Id: string = emptyUuid;
  groupLevel5Id: string = emptyUuid;
  discountPercentage = 0;

  public static createNewFromManufacturerGroupLevelInfo(manufacturerGroupLevelInfo: ManufacturerGroupLevelInfo) {
    const discountManufacturerGroupLevel = new DiscountManufacturerGroupLevel();
    discountManufacturerGroupLevel.id = manufacturerGroupLevelInfo.id;
    discountManufacturerGroupLevel.manufacturerId = manufacturerGroupLevelInfo.manufacturerId;
    discountManufacturerGroupLevel.groupLevel1Id = manufacturerGroupLevelInfo.groupLevel1.id;
    discountManufacturerGroupLevel.groupLevel2Id = manufacturerGroupLevelInfo.groupLevel2.id;
    discountManufacturerGroupLevel.groupLevel3Id = manufacturerGroupLevelInfo.groupLevel3.id;
    discountManufacturerGroupLevel.groupLevel4Id = manufacturerGroupLevelInfo.groupLevel4.id;
    discountManufacturerGroupLevel.groupLevel5Id = manufacturerGroupLevelInfo.groupLevel5.id;
    discountManufacturerGroupLevel.discountPercentage = manufacturerGroupLevelInfo.discountPercentage;

    return discountManufacturerGroupLevel;
  }
}
