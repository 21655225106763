import { Product } from "../product/Product";
import { SearchProduct } from "../search/product/SearchProduct";
import { DiscountRule } from "./DiscountRule";

export class ProductWithDiscountInfo {
  id = "";
  discountRule = "";
  productNumber = "";
  name = "";
  listPrice = 0;
  fixedPrice = 0;
  costPrice = 0;
  discountPercentage = 0;
  contributionMargin = 0;

  public static createFromSearchProduct(product: SearchProduct, discountRule: DiscountRule) {
    const discountProduct = new ProductWithDiscountInfo();
    discountProduct.id = product.id;
    discountProduct.discountRule = discountRule;
    discountProduct.productNumber = product.productNumber;
    discountProduct.name = product.name;
    discountProduct.listPrice = this.getListPrice(product);
    discountProduct.fixedPrice = 0;
    discountProduct.costPrice = this.getCostPrice(product);
    discountProduct.discountPercentage = 0;
    discountProduct.contributionMargin = 0;
    return discountProduct;
  }

  public static createFromProduct(
    product: Product,
    discountRule: DiscountRule,
    fixedPrice: number,
    discountPercentage: number
  ) {
    const discountProduct = new ProductWithDiscountInfo();
    discountProduct.id = product.id;
    discountProduct.discountRule = discountRule;
    discountProduct.productNumber = product.productNumber;
    discountProduct.name = product.name;
    discountProduct.listPrice = this.getListPrice(product);
    discountProduct.fixedPrice = fixedPrice;
    discountProduct.costPrice = this.getCostPrice(product);
    discountProduct.discountPercentage = discountPercentage;
    discountProduct.contributionMargin = 0;
    return discountProduct;
  }

  private static getListPrice(product: SearchProduct | Product) {
    return product.prices.find((price) => price.listPrice !== 0)?.listPrice ?? 0;
  }

  private static getCostPrice(product: Product | SearchProduct) {
    const mainSupplier = product.supplierPrices.find((s) => s.isDefaultSupplier === true);
    return mainSupplier?.replacementCost ?? 0;
  }
}
