import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { duplicatedDataApi } from "./DuplicatedDataApi";
import { Customer } from "@/discount/model/customer/Customer";
import { CustomerGroup } from "@/discount/model/customer-group/CustomerGroup";

export function useDuplicatedDataService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomerGroupsByName = async (name: string): Promise<CustomerGroup[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await duplicatedDataApi.getCustomerGroupsByName(authHeaders, name);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCustomersByIds = async (ids: string[]): Promise<Customer[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await duplicatedDataApi.getCustomersByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCustomerGroupsByIds = async (ids: string[]): Promise<CustomerGroup[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await duplicatedDataApi.getCustomerGroupsByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCustomerGroupsByName,
    getCustomersByIds,
    getCustomerGroupsByIds,
  };
}
