import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../b2c/OAuth2Redirect.vue"),
        },
        {
          name: "Logout",
          path: "/oauth2-signout",
          component: (): Component => import("../b2c/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../b2c/Failed.vue"),
        },
      ]
    : [];
