<template>
  <div class="flex flex-wrap my-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        @click="emit('cancelClicked')"
        data-testid="header-cancel-button"
        id="header-cancel-button"
        size="large"
        variant="text"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ discountName }}</span>
      </Button>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
        <Button
          data-testid="header-save-button"
          class="c-circular-button mr-4"
          @click="emit('saveClicked')"
          id="header-save-button"
          variant="text"
          size="small"
          :disabled="isSaving || !hasUpdates"
        >
          <ProgressSpinner v-if="isSaving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </Button>
      </div>

      <Button
        v-if="isEditing"
        class="c-circular-button mr-4"
        @click="onConfirmDelete"
        data-testid="header-delete-button"
        variant="text"
        size="small"
        :disabled="isDeleting"
      >
        <ProgressSpinner v-if="isDeleting" class="c-spinner" />
        <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.delete") }}</span>
      </Button>
      <Button class="c-circular-button" @click="onToggleAllPanels" variant="text" size="small">
        <i class="pi pi-clone c-default-button c-circular-icon"></i>
        <span class="px-3">{{ collapsedPanels ? t("common.open-panels") : t("common.close-panels") }}</span>
      </Button>
      <ConfirmPopup data-testid="discount-delete-confirm"></ConfirmPopup>
    </div>
  </div>

  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>

  <!-- Css Hack for PrimeVue -->
  <Dialog></Dialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const props = defineProps<{
  isSaving: boolean;
  isDeleting?: boolean;
  isEditing: boolean;
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
  collapsedPanels: boolean;
  discountName?: string;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "cancelClicked"): void;
  (e: "deleteClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
  (e: "dialogDiscardBtnClicked"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
}>();

const { t } = useI18n();
const confirm = useConfirm();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}

.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
