<template>
  <DataTable
    :value="customerGroups"
    stripedRows
    dataKey="id"
    ref="customerGroupListRef"
    class="c-datatable"
    responsiveLayout="scroll"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="discount-customer-group-list"
  >
    <Column field="name" :header="t('discount.customer-group.name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-customer-group-list-name-${index}`">{{ data[field as keyof typeof data] }}</span>
      </template>
    </Column>
    <Column class="text-center">
      <template #header>
        <div class="w-full">
          <i class="pi pi-trash c-delete-btn" />
        </div>
      </template>

      <template #body="{ data, index }">
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`customer-group-list-delete-button-${index}`"
          @click="onConfirmDelete($event, data)"
        />
      </template>
    </Column>

    <template #empty>
      <span data-testid="discount-customer-group-list-no-found">{{ t("discount.customer-group.no-found") }}</span>
    </template>
  </DataTable>
  <ConfirmPopup data-testid="discount-customer-group-remove-confirm"></ConfirmPopup>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
defineProps<{
  customerGroups: CustomerGroup[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "removeCustomerGroupClicked", value: CustomerGroup): void;
}>();

const { t } = useI18n();

const onConfirmDelete = (event: Event, customerGroup: CustomerGroup) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("removeCustomerGroupClicked", customerGroup);
    },
  });
};
</script>

<style scoped lang="scss">
.c-delete-btn {
  color: var(--delete-btn-bg);
}
</style>
