<template>
  <ActiveFilters v-model:filters="filtersComputed" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import ActiveFilters from "./ActiveFilters.vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";

const props = defineProps<{
  filters: DiscountSearchFilters;
}>();

const emit = defineEmits<{
  (e: "update:filters", value: DiscountSearchFilters): void;
}>();

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: DiscountSearchFilters) => {
    emit("update:filters", value);
  },
});
</script>
