<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        @click="router.push({ name: DiscountRoutes.Add })"
        data-testid="btn-add"
        id="btn-add"
        variant="text"
        size="small"
      >
        <i class="pi pi-plus c-success-button c-circular-icon text-xs rounded-3xl p-2 text-white"></i>
        <span class="font-normal">{{ t("common.add") }}</span>
      </Button>
    </div>
    <div>
      <Button
        data-testid="btn-selected-icon"
        @click="(event: Event) => emit('openSortList', event)"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isSortingVisible }"
        v-tooltip.bottom="{
          value: t('discount.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        @click="emit('refreshList')"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('discount.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        @click="(event: Event) => emit('openColumnChooser', event)"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isChooserVisible }"
        v-tooltip.bottom="{
          value: t('discount.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-filter"
        @click="emit('openFilterSidebar')"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isFilterVisible }"
        v-tooltip.bottom="{
          value: t('discount.toolbar.filter'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();

import { DiscountRoutes } from "../routes";

const isSortingVisible = defineModel<boolean>("isSortingVisible");
const isChooserVisible = defineModel<boolean>("isChooserVisible");
const selectedIcon = defineModel<string>("selectedIcon");
const isFilterVisible = defineModel<boolean>("isFilterVisible");

const emit = defineEmits<{
  (e: "refreshList"): void;
  (e: "openSortList", event: Event): void;
  (e: "openColumnChooser", event: Event): void;
  (e: "openFilterSidebar"): void;
}>();
</script>
