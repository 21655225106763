<template>
  <h4 class="text-center">{{ t("common.status") }}</h4>
  <div
    v-for="(value, index) in discountStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md my-2"
    :class="{
      'c-list-row-bg dark:border-white dark:border-2 mt-1': index % 2 === 0,
      'c-list-alternate-row-bg dark:border-white dark:border-2 mt-1': index % 2 === 1,
    }"
  >
    <label
      class="font-light"
      :for="'discount-status-' + value.status.toLowerCase()"
      :data-testid="'c-discount-status-label-' + value.status.toLowerCase()"
      >{{ t(`discount.status.${value.status.toLowerCase()}`) }}</label
    >
    <Checkbox
      v-model="selectedDiscountStatusesComputed"
      :value="value.status"
      :inputId="'discount-status-' + value.status.toLowerCase()"
      :data-testid="'c-discount-status-checkbox-' + value.status.toLowerCase()"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DiscountStatus } from "../model/search/discount/DiscountStatus";

const props = defineProps<{
  selectedDiscountStatuses: DiscountStatus[];
}>();

const emit = defineEmits<{
  (event: "update:selectedDiscountStatuses", value: string[]): void;
}>();

const { t } = useI18n();

const selectedDiscountStatusesComputed = computed<string[]>({
  get: () => {
    return props.selectedDiscountStatuses ? props.selectedDiscountStatuses : [];
  },
  set: (value: string[]) => {
    emit("update:selectedDiscountStatuses", value);
  },
});

const discountStatusesComputed = computed(() => {
  const allStatuses = Object.values(DiscountStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});
</script>

<style scoped lang="scss">
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
.dark .c-list-row-bg {
  background-color: var(--p-surface-950);
  color: var(--p-slate-100);
  border: 1px solid var(--p-surface-600);
}

.dark .c-list-alternate-row-bg {
  background-color: var(--p-surface-950);
  color: var(--p-slate-100);
  border: 1px solid var(--p-surface-600);
}
</style>
