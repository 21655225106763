import {
  initMsalInstance,
  msalPlugin,
  msalInstance,
  AuthenticationResult,
  EventType,
  LogLevel,
} from "@cumulus/event-bus";
import { createApp } from "vue";

export default async function configureAzureB2C(
  app: ReturnType<typeof createApp>,
  standalone: boolean,
  apiUrl: string,
  b2cDomain: string,
  b2cClientId: string,
  b2cInstance: string,
  log = "error"
) {
  const logLevel = (level: string): LogLevel => {
    if (!level) {
      return LogLevel.Info;
    }

    switch (level.toLocaleLowerCase()) {
      case "error":
        return LogLevel.Error;
      case "warning":
        return LogLevel.Warning;
      case "info":
        return LogLevel.Info;
      case "verbose":
        return LogLevel.Verbose;
    }

    return LogLevel.Info;
  };

  if (standalone) {
    initMsalInstance(apiUrl, b2cDomain, b2cClientId, b2cInstance, logLevel(log));
  }

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance().then((msal) => {
    const accounts = msal.getAllAccounts();
    if (accounts.length > 0) {
      msal.setActiveAccount(accounts[0]);
    }
  });

  msalInstance().then((msal) => {
    msal.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msal.setActiveAccount(account);
      }
    });
  });

  app.use(msalPlugin, await msalInstance());
  return msalInstance;
}
