<template>
  <div class="c-minimal-inputtext c-minimal-inputgroup mr-8discount-active-for-all-customers ml-4">
    <div class="p-inputgroup">
      <InputText
        ref="searchInput"
        class="w-full"
        v-model="query"
        v-debounce:200="search"
        :placeholder="t('discount.customer.search')"
        @keydown.enter.prevent="focusSearchResult"
        @keydown.tab="focusSearchResult"
        @keydown.esc="handleEscape($event)"
        @input="showSearchOverlayPanel($event)"
        @focus="showSearchOverlayPanel($event)"
        aria-controls="customer_overlay_panel"
        data-testid="discount-customer-search"
        :disabled="props.activeForAllCustomers"
      />
      <span class="p-button p-inputgroup-addon bg-transparent border-none z-10 -ml-12"
        ><i class="pi pi-search text-zinc-500"
      /></span>
    </div>
  </div>
  <Popover
    ref="overlayPanelRef"
    appendTo="body"
    :showCloseIcon="false"
    :dismissable="searchResultPanelDismissable"
    id="customer_overlay_panel"
    :breakpoints="{ '1250px': '100vw' }"
    :style="{ width: '1250px' }"
    @keydown.esc.stop="hideSearchOverlayPanel"
  >
    <CustomerSearchList
      ref="customerListRef"
      :customers="customers"
      :loading="loading"
      @customerSelected="onCustomerSelected"
    />
  </Popover>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useSearchService } from "../api/search/SearchService";
import { SearchCustomer } from "../model/search/customer/SearchCustomer";
import { CustomerSearchResponse } from "../model/search/customer/CustomerSearchResponse";
import { CustomerSearchRequest } from "../model/search/customer/CustomerSearchRequest";
import CustomerSearchList from "./CustomerSearchList.vue";

const props = defineProps<{
  activeForAllCustomers: boolean;
}>();

const emit = defineEmits<{
  (e: "customerSelected", value: SearchCustomer): void;
}>();

const { t } = useI18n();
const { customerSearch } = useSearchService();

const query = ref<string>("");
const customers = ref<SearchCustomer[]>([]);
const loading = ref<boolean>(false);
const overlayPanelRef = ref();
const searchResultPanelDismissable = ref(true);
const selectedCustomer = ref<SearchCustomer | null>(null);
const customerListRef = ref<ComponentPublicInstance<HTMLInputElement>>();
const searchInput = ref();

const onCustomerSelected = (customer: SearchCustomer) => {
  emit("customerSelected", customer);
  hideSearchOverlayPanel();
};

const search = async () => {
  try {
    loading.value = true;
    const request = new CustomerSearchRequest(query.value);
    const response: CustomerSearchResponse = await customerSearch(request);
    customers.value = response.customers;
  } finally {
    loading.value = false;
  }
};

const focusSearchResult = () => {
  if (customers.value.length > 0 && customerListRef.value) {
    nextTick(() => {
      const firstTableRow = customerListRef.value?.$el.querySelector("tbody tr:first-of-type");
      if (firstTableRow) {
        firstTableRow.focus();
      }
    });
  }
};

const handleEscape = (event: KeyboardEvent) => {
  if (query.value !== "") {
    query.value = "";
    hideSearchOverlayPanel();
    event.stopPropagation();
  }
};

const hideSearchOverlayPanel = () => {
  searchInput.value.$el.focus();
  selectedCustomer.value = null;
  overlayPanelRef.value.hide();
};

const showSearchOverlayPanel = (event: Event) => {
  if (query.value === "") {
    overlayPanelRef.value.hide();
    return;
  }
  overlayPanelRef.value.show({ currentTarget: event.target });
};
</script>
<style scoped lang="scss">
:deep(input.p-inputtext.p-component) {
  border: none;
  border-bottom: var(--floating-input-border);
  border-radius: 0;
  padding: 0.25rem;
}
</style>
