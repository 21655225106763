import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Customer } from "@/discount/model/customer/Customer";
import { CustomerGroup } from "@/discount/model/customer-group/CustomerGroup";

export class DuplicatedDataApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != "undefined" && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getCustomerGroupsByName(authHeaders: AuthHeaders, name: string): Promise<CustomerGroup[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/customer-groups", {
        params: { name: name },
      })
      .then((response) => response.data);
  }

  public async getCustomersByIds(authHeaders: AuthHeaders, ids: string[]): Promise<Customer[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/customers", { params: { ids }, paramsSerializer: { indexes: null } })
      .then((response) => response.data);
  }

  public async getCustomerGroupsByIds(authHeaders: AuthHeaders, ids: string[]): Promise<CustomerGroup[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/customer-groups", { params: { ids }, paramsSerializer: { indexes: null } })
      .then((response) => response.data);
  }
}

export const duplicatedDataApi = new DuplicatedDataApi();
