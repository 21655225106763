import { DiscountType } from "../../discount/DiscountType";
import { DiscountRule } from "../../discount/DiscountRule";

export class SearchDiscount {
  id = "";
  registered = "";
  registeredByEmployeeInitials = "";
  name = "";
  description = "";
  discountType: DiscountType | null = null;
  discountRule: DiscountRule | null = null;
  activeFrom = "";
  activeTo = "";
  active = false;
}
