import { setupI18n } from "@cumulus/locale";
import { LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import commonLocale from "./locales/en.json";
import discountLocale from "./discount/locales/en.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const commonMessages = await import(`./locales/${locale}.json`);
  const discountMessages = await import(`./discount/locales/${locale}.json`);

  const messages = { ...commonMessages.default, ...discountMessages.default };
  return messages as LocaleMessageDictionary<VueMessageType>;
}

export const i18n = setupI18n({ ...commonLocale, ...discountLocale } as unknown as string, loadMessagesAsync);
