<template>
  <ToolbarSearchList
    :selectedIcon="selectedIcon"
    :isFilterVisible="props.visible"
    :isSortingVisible="isSortingVisible"
    :isChooserVisible="isChooserVisible"
    @refreshList="emit('refreshList')"
    @openSortList="openSortList"
    @openColumnChooser="openColumnChooser"
    @openFilterSidebar="emit('openFilterSidebar')"
  >
  </ToolbarSearchList>
  <div class="flex items-left gap-2">
    <SearchHeader v-model:filters="filtersComputed" />
  </div>
  <div>
    <DataTable
      :key="renderKey as unknown as number"
      :value="discounts"
      dataKey="id"
      class="c-datatable"
      :paginator="true"
      :lazy="true"
      :loading="loading"
      :rows="pageSize"
      :totalRecords="totalHits"
      :currentPageReportTemplate="
        t('discount.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      ref="searchDiscountList"
      v-model:selection="selectedDiscount"
      :stripedRows="true"
      selectionMode="single"
      data-testid="discount-search-result"
      tabindex="0"
      :rowsPerPageOptions="[50, 100]"
      :sortOrder="sortOrder"
      :sortField="sortField"
      removableSort
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClick"
      @sort="onSort"
      @page="onPage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
      :rowClass="addGlobalSearchClass"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      :reorderable-columns="true"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="t(col.header)"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === DiscountLinesColumns.Registered">
            <span :data-testid="`c-discount-list-registered-${index}`">{{ d(data[field as keyof typeof data]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.RegisteredByEmployeeInitials">
            <span :data-testid="`c-discount-list-user-${index}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Name">
            <span
              class="c-discount-name-text"
              :data-testid="`c-discount-list-name-${index}`"
              @click="onDiscountNameClicked(data)"
              >{{ data[field as keyof typeof data] }}</span
            >
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Description">
            <span :data-testid="`c-discount-list-description-${index}`">{{ data[field as keyof typeof data] }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.DiscountType">
            <span :data-testid="`c-discount-list-type-${index}`">{{
              t(`discount.type.${data[field as keyof typeof data].toLowerCase()}`)
            }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.DiscountRule">
            <span :data-testid="`c-discount-list-rule-${index}`">{{
              t(`discount.rule.${data[field as keyof typeof data].toLowerCase()}`)
            }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.ActiveFrom">
            <span :data-testid="`c-discount-list-valid-from-${index}`">{{ d(data[field as keyof typeof data]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.ActiveTo">
            <span :data-testid="`c-discount-list-valid-to-${index}`">{{ d(data[field as keyof typeof data]) }}</span>
          </template>
          <template v-else-if="col.field === DiscountLinesColumns.Active">
            <Checkbox
              v-model="data[field as keyof typeof data]"
              disabled
              :binary="true"
              :data-testid="`c-discount-list-active-${index}`"
            />
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>
      <template #empty
        ><span data-testid="c-dicount-list-not-found">{{ t("discount.not-found") }}</span></template
      >
    </DataTable>
    <Popover ref="op" data-testid="sort-list">
      <div class="flex flex-col gap-4 w-[14rem] p-2">
        <div>
          <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
          <ul class="list-none p-2 m-0">
            <li
              v-for="column in ordersReadyForPickingSearchColumns"
              :key="column.field"
              class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
              :class="[
                'transition-colors duration-200',
                {
                  'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                  'hover:bg-emphasis': !isSelectedColumn(column),
                },
              ]"
              @click="onSelectedRow(column)"
            >
              <div class="flex-1">
                <span class="font-medium">{{ t(column.header) }}</span>
              </div>
              <div class="text-right">
                <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Popover>

    <PopOverColumnChooser
      ref="chooserRef"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      @resetColumns="resetColumns"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SearchDiscount } from "../model/search/discount/SearchDiscount";
import { ref, nextTick, watch, computed } from "vue";

import type {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";

import { DiscountLinesColumns } from "../model/search/discount/DiscountLinesColumns";

import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import SearchHeader from "./SearchHeader.vue";
import { DiscountSearchFilters } from "../model/search/discount/DiscountSearchFilters";
import ToolbarSearchList from "./ToolbarSearchList.vue";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";

const { t, d } = useI18n();
const searchDiscountList = ref();
const selectedDiscount = ref<SearchDiscount>(new SearchDiscount());

const props = defineProps<{
  discounts: SearchDiscount[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  employeeId: string;
  visible: boolean;
  filters: DiscountSearchFilters;
}>();

const emit = defineEmits<{
  (e: "rowDblClicked", value: string): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "refreshList"): void;
  (e: "openFilterSidebar"): void;
  (e: "update:filters", value: DiscountSearchFilters): void;
}>();

watch([() => props.discounts, () => props.loading], ([discounts, loading]) => {
  if (loading === false && discounts.length > 0) {
    nextTick(() => {
      focusSearchResult(selectedDiscount.value.id);
    });
  }
});

const ordersReadyForPickingSearchColumns: DataTableColumn[] = [
  { field: "registered", header: "discount.registered", sortable: true },
  { field: "registeredByEmployeeInitials", header: "discount.user", sortable: true },
  {
    field: "name",
    header: "discount.name",
    sortable: true,
  },
  {
    field: "description",
    header: "discount.description",
    sortable: true,
  },
  {
    field: "discountType",
    header: "discount.type.label",
    sortable: true,
  },
  { field: "discountRule", header: "discount.rule.label", sortable: true },
  { field: "activeFrom", header: "discount.valid-from", sortable: true },
  { field: "activeTo", header: "discount.valid-to", sortable: true },
  { field: "active", header: "discount.active", sortable: true },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("discountSearch", ordersReadyForPickingSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(
  ordersReadyForPickingSearchColumns.find((column) => column.field === DiscountLinesColumns.Name),
);
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult(selectedDiscount.value.id);
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: DiscountSearchFilters) => {
    emit("update:filters", value);
  },
});

const addGlobalSearchClass = (data: SearchDiscount) => {
  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedDiscount.value?.id === data?.id },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  selectedDiscount.value = event.data;
  emit("rowDblClicked", event.data.id);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  selectedDiscount.value = event.data;
  emit("rowDblClicked", event.data.id);
};
const focusSearchResult = (id: string) => {
  nextTick(() => {
    if (props.discounts.length > 0 && searchDiscountList.value.$el) {
      const element = id === "" ? null : searchDiscountList.value.$el.querySelector(`tr.c-sr-id-${id}`);
      if (element) {
        (element as HTMLElement).focus();
        return;
      }

      searchDiscountList.value.$el.querySelector("tbody tr:first-of-type").focus();
    }
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult(selectedDiscount.value.id);

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult(selectedDiscount.value.id);
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onDiscountNameClicked = (discount: SearchDiscount) => {
  selectedDiscount.value = discount;
  emit("rowDblClicked", discount.id);
};
</script>
<style scoped lang="scss">
.c-discount-name-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
