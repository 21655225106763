<template>
  <DataTable
    :value="products"
    stripedRows
    dataKey="id"
    ref="productSearchListRef"
    class="c-datatable"
    responsiveLayout="scroll"
    selectionMode="single"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClick"
    scrollHeight="400px"
    :loading="loading"
    :rowHover="true"
    data-testid="product-search-result"
  >
    <Column field="productNumber" :header="t('discount.product.product-number')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-product-search-result-product-number-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="name" :header="t('discount.product.product-name')">
      <template #body="{ data, field, index }">
        <span :data-testid="`discount-product-search-result-name-${index}`">{{ data[field] }}</span>
      </template>
    </Column>

    <template #empty>
      {{ t("discount.product.no-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { SearchProduct } from "../model/search/product/SearchProduct";

defineProps<{
  products: SearchProduct[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "productSelected", value: SearchProduct): void;
  (e: "removeProductClicked", value: SearchProduct): void;
}>();

const { t } = useI18n();

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  emit("productSelected", event.data as SearchProduct);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("productSelected", event.data as SearchProduct);
};
</script>
