import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Discount } from "@/discount/model/discount/Discount";

class DiscountApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_CATALOG !== "undefined" && import.meta.env.VITE_APP_API_CATALOG
        ? import.meta.env.VITE_APP_API_CATALOG
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Discount> {
    return await httpClient(this.uri, authHeaders)
      .get(`/discounts/${id}`)
      .then(({ data }) => data);
  }

  public async getByName(authHeaders: AuthHeaders, name: string): Promise<Discount | null> {
    return await httpClient(this.uri, authHeaders)
      .get(`/discounts?discountName=${name}`)
      .then((response) => response.data);
  }

  public async add(authHeaders: AuthHeaders, discount: Discount): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .post("/discounts", discount)
      .then((response) => response.data.discountId);
  }

  public async update(authHeaders: AuthHeaders, discount: Discount): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/discounts", discount);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(`/discounts/${id}`);
  }
}

export const discountApi = new DiscountApi();
