import { Ref } from "vue";
import { CustomerGroup } from "../model/customer-group/CustomerGroup";
import { Discount } from "../model/discount/Discount";

export function discountCustomerGroupFunctions(discount: Ref<Discount>, selectedCustomerGroups: Ref<CustomerGroup[]>) {
  const addCustomerGroupToDiscount = (customerGroup: CustomerGroup) => {
    const existingCustomerGroup = discount.value.customerGroupIds.find((x: string) => x === customerGroup.id);

    if (existingCustomerGroup) return;

    selectedCustomerGroups.value.push(customerGroup);
    discount.value.customerGroupIds.push(customerGroup.id);
  };

  const removeCustomerGroupFromDiscount = (customerGroup: CustomerGroup) => {
    discount.value.customerGroupIds = discount.value.customerGroupIds.filter((x: string) => x !== customerGroup.id);
    selectedCustomerGroups.value = selectedCustomerGroups.value.filter((x: CustomerGroup) => x.id !== customerGroup.id);
  };

  return {
    addCustomerGroupToDiscount,
    removeCustomerGroupFromDiscount,
  };
}
