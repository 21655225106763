<template>
  <FloatLabelDropdown
    id="discount-client"
    :options="dropdownOptions"
    dataTestId="discount-client"
    v-model:value="selectedClientId"
    :placeholder="t('placeholder.select', { property: t('discount.client').toLowerCase() })"
    :label="t(`discount.client`)"
    :isFloatingLabel="isFloatingLabel"
  />
</template>

<script setup lang="ts">
import { Client } from "../model/client/Client";
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useClientService } from "../api/company/ClientService";

const props = defineProps<{
  clientId: string;
}>();

const emit = defineEmits<{
  (e: "update:clientId", value: string): void;
}>();

const { t } = useI18n();
const { getAllClients } = useClientService();

const clients = ref<Client[]>([]);
const loading = ref<boolean>(false);

const selectedClientId = computed<string>({
  get: () => {
    return props.clientId ? props.clientId : "";
  },
  set: (value: string) => {
    emit("update:clientId", value);
  },
});

const fetchAllClients = async () => {
  try {
    loading.value = true;
    clients.value = await getAllClients();
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  fetchAllClients();
});

const dropdownOptions = computed(() => {
  return clients.value.map((client) => {
    return { name: client.name, value: client.id };
  });
});

const isFloatingLabel = computed(() => {
  return selectedClientId.value !== "00000000-0000-0000-0000-000000000000";
});
</script>
../api/company/ClientService
