<template>
  <div class="mt-5">
    <FloatLabel variant="on">
      <Select
        id="discount-rule"
        :options="discountRulesComputed"
        optionLabel="name"
        optionValue="value"
        data-testid="discount-rule"
        v-model="selectedRule"
        class="w-full"
        :invalid="val.rule.$error"
        pt:list:data-testid="discount-rule-list"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('discount.rule.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />

      <label for="discount-rule">
        {{ t(`discount.rule.label`) }}
      </label>
    </FloatLabel>
    <small id="discount-rule-help" data-testid="discount-rule-error" class="p-error" v-if="val.rule.$error">{{
      val.rule.$errors[0].$message
    }}</small>
  </div>
</template>

<script setup lang="ts">
import { required } from "../../i18n-validators";
import { DiscountRule } from "../model/discount/DiscountRule";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  rule: DiscountRule | null;
}>();

const emit = defineEmits<{
  (e: "update:rule", value: DiscountRule): void;
  (e: "discountRuleChanged", value: DiscountRule): void;
}>();

const selectedRule = computed<DiscountRule | null>({
  get: () => {
    return props.rule ? props.rule : null;
  },
  set: (value: DiscountRule | null) => {
    if (!value) return;
    emit("discountRuleChanged", value);
    emit("update:rule", value);
  },
});

const discountRulesComputed = computed(() => {
  const discountRules = Object.values(DiscountRule);
  return discountRules.map((discountRule) => {
    return {
      name: t(`discount.rule.${discountRule.toLowerCase()}`),
      value: discountRule,
    };
  });
});

const rules = {
  rule: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
